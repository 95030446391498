.form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-container > h2 {
  display: flex;
  align-items: center;
}

.form-container > h2 > span {
  padding-right: var(--padding-level-3);
}

.form-container > form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-container > form > label > input {
  flex: 1;
}
