.container {
  width: 100%;
  margin: 0 auto;
  max-width: var(--kudo-max-w);

  display: flex;
  gap: 2rem;
}

.navigation_card {
  height: min-content;
  @media only screen and (max-width: 768px) {
    padding: 0 0 2rem 0;
    box-shadow: none;
    background: transparent;
  }
}

.content_card {
  flex: 1;

  @media only screen and (max-width: 768px) {
    box-shadow: none;
    border-radius: initial;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    gap: 0rem;
  }
}

.menu_card_content {
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 1rem;
  width: 8.8rem;
  height: min-content;
}

.menu_card_content a {
  display: flex;
  align-items: center;
  transition: color 0.5s;
  cursor: pointer;
  color: var(--kabisa-black-300);
}

.menu_card_content a span {
  color: var(--kabisa-black-300);
  transition: color 0.5s;
}

.menu_card_content a:hover {
  color: var(--kabisa-black);
}

.menu_card_content a:hover > span {
  color: var(--kabisa-black);
}

.menu_card_content a > span {
  padding-right: var(--padding-level-5);
}

.menu_card_content > .active_item {
  color: var(--kabisa-black);
  font-weight: bold;
}

.menu_card_content > .active_item span {
  color: var(--kabisa-black);
}
