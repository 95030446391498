.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: var(--font-color-1);
}

.wrapper {
  width: 240px;
  height: 240px;
  margin: auto;
  position: relative;
}

.current {
  font-size: 40px;
  font-weight: bold;
  margin: 0;
}

.kudo_symbol {
  font-size: 35px;
  margin: 0;
  padding: 0;
}

.needed {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding-top: 0;
}
