.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.container > h2 {
  display: flex;
  align-items: center;
}

.container > h2 > span {
  padding-right: var(--padding-level-3);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form > label > input {
  flex: 1;
}

.button {
  height: min-content;
}
