.container {
  --base-size: var(--font-size-m);

  height: 100px;
}

.lock_container {
  position: absolute;

  width: calc(var(--base-size) * 2);
  height: calc(var(--base-size) * 2);

  border-radius: 50%;
}

.lock_icon {
  position: absolute;
  top: calc(var(--base-size) / 2);
  left: calc(var(--base-size) / 2);

  background: transparent !important; /* Temporary fix for CSS order issue */

  font-size: var(
    --base-size
  ) !important; /* Temporary fix for CSS order issue */
}

.bar {
  position: absolute;

  width: 0.75rem;
  height: 80px;
  margin-top: calc(var(--base-size) * 1.5);
  margin-left: calc(var(--base-size) - calc(0.75rem / 2));
}

.progress_bar {
  position: absolute;

  width: 0.75rem;
  margin-left: calc(var(--base-size) - calc(0.75rem / 2));
}

.percentage_banner {
  position: absolute;
  width: 50px;
  height: 2px;
  margin-left: 9px;
}

.current_percentage {
  display: flex;
  justify-content: flex-end;
}

.goal_amount {
  margin-bottom: 2px;
  margin-top: 0;
}

.goal_name {
  margin-bottom: 4px;
}

.goal_needed {
  margin-top: 16px;
  margin-bottom: 0;
}

.bottom_dot {
  position: absolute;

  width: calc(var(--base-size) * 2);
  height: calc(var(--base-size) * 2);

  margin-top: calc(var(--base-size) * 1.5);
  border-radius: 50%;
}
