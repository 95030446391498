.container {
  box-shadow: var(--shadow-level-1);
  border-radius: var(--radii-level-2);
  padding: 2rem;

  background: var(--base-color);
  width: 100%;
}

.container > h2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--margin-level-5);
}

.container > h2 > span {
  padding-right: var(--padding-level-4);
}

@media only screen and (max-width: 768px) {
  .container {
    box-shadow: none;
    border-radius: unset;
  }
}
