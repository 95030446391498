.tabletAndBelowContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: end;
  width: 100%;

  height: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  width: 100%;
}

.create_post_container_mobile {
  padding: 16px;
}

.create_post_segment {
  width: 480px;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
  border: none;

  text-align: left;
}

.divider {
  margin-bottom: 2px;
  margin-top: 0;
}
