.container {
  --line-height: 1.75rem;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.kudos {
  font-size: 1.25rem;
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height);
}

.avatarWrapper {
  display: flex;
  gap: var(--spacing-level-2);
  flex-flow: wrap;
  flex: 1;

  margin-left: var(--spacing-level-5);
}

.timestamp {
  color: #081721;
  font-size: 0.65rem;
}

.avatar {
  height: var(--line-height);
  width: var(--line-height);
}

.utilityContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-level-2);

  height: var(--line-height);
}

.deleteButton {
  /* Temporary fix for CSS order issue */
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;

  background: transparent !important;

  color: var(--brand-color-2) !important;
}

.deleteButton > span:hover {
  /* Temporary fix for CSS order issue */
  border: none !important;
  color: var(--kabisa-green-2) !important;
}
