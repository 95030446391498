.message {
    --border-color: var(--kabisa-black-300);

    border-radius: var(--radii-level-1);
    border: 1px solid var(--border-color);
    padding: var(--padding-level-5);
    margin-bottom: var(--spacing-level-5);

    background: #fff6f6;

    font-family: var(--font-family);
}

.error {
    --border-color: #e0b4b4;

    color: #9f3a38;
}

.success {
    --border-color: var(--kabisa-green-300);

    color: var(--kabisa-green-600);
}

.message > h3 {
    font-size: var(--font-size-m);
}
