.root {
  margin-bottom: 1rem;
}

.text {
  text-align: center;
}

.button {
  margin: 16px;
}

.teamItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.teamList {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}
