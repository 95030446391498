.button_wrapper {
  display: block;
}

.button_white {
  background: #ffffff;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content .button:nth-of-type(1) {
  margin-bottom: 0.5rem;
}

.image {
  margin-top: 32px;
  margin-bottom: 16px;
  border-radius: 50%;
  width: 80px;
  justify-self: center;
}

.image_caption {
  text-align: center;
  margin-bottom: 2rem;
}

.name {
  margin-bottom: 0;
}

.sub_text {
  color: grey;
}

.other_buttons_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .other_buttons_section button {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .other_buttons_section button {
    min-width: 200px;
    width: 170px;
  }
}
