.dropzone {
  background-color: white;
  padding: 1rem;
  border-color: #eeeeee;
  border-style: dashed;
  text-align: center;
  color: rgba(191,191,191,.87);
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.error {
  color: #912d2b;
  display: inline-block;
  margin-top: 0.3rem;
}

.thumbs_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;

  .thumb {
    display: inline-flex;
    background-color: white;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    width: 100px;
    height: 80px;
    padding: 0.3rem;
  }

  .img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}