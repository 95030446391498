.container {
  padding: 2rem;

  color: var(--brand-color-1);
}

.root {
  padding: 32px;
  text-align: center;
  width: 100%;
  height: calc(100vh - 57px);
  overflow: auto;

}

.next_goal {
  font-size: 21px;
  font-weight: bold;

  margin-bottom: 1rem;
}

.goals {
  padding: 0 32px;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.circle_container {
  margin-top: 32px;
}

.goal_container {
  padding-top: 48px;
  position: relative;
}
