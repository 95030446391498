.primary {
  font-size: var(--font-size-xl);
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  color: var(--font-color-2);
}

.secondary {
  font-size: var(--font-size-l);
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  color: var(--font-color-1);
}

.light {
  color: var(--font-color-1);
}

.dark {
  color: var(--font-color-2);
}
