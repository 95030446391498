.button {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.footer a {
  text-decoration: none;
}
