table {
  width: 100%;
  text-indent: 0;
  border-collapse: collapse;
}

th {
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0px;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid var(--kabisa-black-300);
}

tr {
  border-bottom: 1px solid var(--kabisa-black-200);
}

td {
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  word-wrap: break-word;
}

table tr:last-child {
  border-bottom: none;
}

table td > button:not(:first-child) {
  margin-left: var(--margin-level-3);
}

@media only screen and (max-width: 768px) {
  table td > button:not(:first-child) {
    margin-left: 0;
  }
}
