.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section h2 {
  margin-bottom: var(--margin-level-3);
}
