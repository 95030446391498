.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions {
  margin-top: var(--margin-level-5);
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-level-3);

  max-width: 420px;
  margin: auto;
}
