.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-level-3);
}

.form textarea {
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  font-size: var(--font-size-s);
  resize: vertical;
  border: 0.1rem solid var(--subtle-color);
  border-radius: var(--radii-level-1);
}

.button {
  align-self: center;

  font-weight: var(--font-weight-bold);
}

.note {
  color: grey;
  font-size: 0.575rem;
}
