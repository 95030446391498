.wrapper {
  display: flex;
  flex-direction: column;
  max-width: var(--kudo-max-w);
  margin: 0 auto;
  padding: 2rem;
  width: 600px;
}

.form_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.form_wrapper .header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .form_wrapper .header.center {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    width: auto;
    padding: 0;
  }
}
