.back_link {
  width: 70px;
  height: 100%;
  color: #ffffff;
  background: transparent;
}

.icon {
  font-size: var(--font-size-xl);
  color: var(--kabisa-text);
}

.icon:hover {
  cursor: pointer;
}

.toolbar_text {
  line-height: 68px;
  font-size: var(--font-size-m);
}

.top_navigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
}

.button:hover > span {
  color: var(--kabisa-green);
}
