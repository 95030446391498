.transaction {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-level-4);

  width: 100%;
}

.images {
  margin-top: 0.5rem;

  .image {
    width: 100px;
    height: 80px;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    object-fit: cover;
    cursor: pointer;
  }
}
