.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container a {
  text-decoration: none;
}

.button {
  display: block;
  margin-top: 16px;
}

@media only screen and (min-width: 768px) {
  .button {
    width: 170px;
  }
}

.grey {
  color: #808080;
}

.name {
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .button {
    width: 100%;
  }
}
