:root {
  --kabisa-green-600: #24b371;
  --kabisa-green-500: #41c387;
  --kabisa-green-400: #50d296;
  --kabisa-green-300: #50d296;
  --kabisa-green-200: #50d296;
  --kabisa-green-100: #50d296;

  --kabisa-black-800: #18242b;
  --kabisa-black-700: #28363d;
  --kabisa-black-600: #364147;
  --kabisa-black-500: #535c61;
  --kabisa-black-400: #727e85;
  --kabisa-black-300: #9ca7ad;
  --kabisa-black-200: #e1e7eb;
  --kabisa-black-100: #f7f9fa;

  --kudo-text: #fff;

  --kabisa-green-1: var(--kabisa-green-500);
  --kabisa-green-2: var(--kabisa-green-400);
  --kabisa-black: var(--kabisa-black-800);

  --kudo-background: var(--kabisa-black-200);
}
