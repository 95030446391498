.container {
  display: flex;
  align-items: center;
  gap: var(--spacing-level-4);
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-level-3);

  border: 0.1rem solid var(--kabisa-black-400);
  border-radius: var(--radii-level-2);
  padding: var(--padding-level-1) var(--padding-level-3);

  font-size: var(--font-size-xs);
  color: var(--kabisa-black-500);
  background-color: var(--bg-color-1);

  cursor: pointer;
}

.buttonContainer:hover {
  color: var(--font-color-2);
  background-color: var(--kabisa-black);
}

.buttonContainer:hover > span {
  color: var(--font-color-2);
}

.button {
  /* Temporary fix for CSS order issue */
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;

  font-size: inherit !important;
}

.button:hover {
  cursor: pointer;
}

.likes {
  position: relative;

  font-size: var(--font-size-xs);
  color: var(--kabisa-black-500);
}

.all_likes_container {
  position: absolute;
  top: 1.5rem;
  left: 0;

  border-radius: var(--radii-level-2);
  padding: var(--padding-level-3);

  background: var(--brand-color-3);
  color: var(--font-color-1);
}
